import "@styles/app.sass"
import Swiper, {Autoplay, EffectFade, Pagination, Thumbs} from "swiper"
import Swup from "swup"
import SwupBodyClassPlugin from '@swup/body-class-plugin'
import SwupHeadPlugin from '@swup/head-plugin'
import SwupScrollPlugin from '@swup/scroll-plugin'
import Rellax from "rellax"
import lightGallery from "lightgallery"
import lgVideo from "lightgallery/plugins/video"

let swup = undefined,
    navOpener = undefined

if (!document.body.classList.contains('admin-bar')) {
    swup = new Swup({
        plugins: [
            new SwupBodyClassPlugin(),
            new SwupHeadPlugin(),
            new SwupScrollPlugin()
        ],
        ignoreVisit: (url, { el } = {}) => el?.parentElement.classList.contains('wp-block-image')
            || el?.closest('#wpadminbar')
    })
}

const init = () => {
    (() => { // Hiding header
        const header = document.querySelector('[data-page-header]') as HTMLElement;
        const headerOffset = header.offsetTop - window.scrollY,
            headerHeight = (header.children[0] as HTMLElement)?.offsetHeight ?? 0;

        let lastScroll = 0;

        const hidingOnScroll = () => {
            const currentScroll = window.scrollY;

            if (currentScroll > headerOffset + headerHeight) {
                header.classList.add('is-sticked')
                header.classList[currentScroll > lastScroll ? 'add' : 'remove']('is-up')
                document.body.classList[currentScroll > lastScroll ? 'remove' : 'add']('is-sticked-header')
            } else {
                header.classList.remove('is-sticked')
                header.classList.remove('is-up')
                document.body.classList.remove('is-sticked-header')
            }

            lastScroll = currentScroll;
        }

        hidingOnScroll();
        window.addEventListener('scroll', () => hidingOnScroll(), { passive: true });

        navOpener = document.querySelector('[data-nav-opener]')
        navOpener.addEventListener("click", () => {
            document.body.classList.toggle('u-hiddenOverflow');
            navOpener.closest('[data-page-header]')?.classList.toggle('is-opened');
        })
    })();

    (() => { // home hero swiper
        const slider = document.querySelector('[ data-testimonial-items]') as HTMLElement;
        if (!slider) return;

        const photosSwiper = new Swiper("[data-testimonial-photos]", {
            slidesPerView: 1,
            allowTouchMove: false,
            watchSlidesProgress: true,
            modules: [ EffectFade ],
            effect: 'fade',
            fadeEffect: {
                crossFade: true
            }
        })

        new Swiper(slider, {
            cssMode: true,
            slidesPerView: 1,
            modules: [ Autoplay, Pagination, Thumbs ],
            thumbs: {
                swiper: photosSwiper
            },
            autoplay: {
                delay: 8000,
            },
            pagination: {
                el: '[data-testimonial-pagination]',
                type: 'bullets',
                bulletActiveClass: 'is-active',
                clickable: true
            },
        });
    })();

    (() => { // pageContent gallery
        const galleries = document.querySelectorAll('.wp-block-gallery');
        galleries.forEach((gallery: HTMLElement) => lightGallery(gallery, {
            selector: 'figure > a',
            speed: 400,
            download: false,
            getCaptionFromTitleOrAlt: false,
            mobileSettings: {
                showCloseIcon: true
            }
        }));
    })();

    (() => { // pageContent gallery
        const video = document.querySelector('[data-home-video]') as HTMLElement;
        video && lightGallery(video, {
            selector: 'a',
            plugins: [lgVideo],
            download: false,
            mobileSettings: {
                showCloseIcon: true
            }
        })
    })();

    new Rellax('[data-parallax]', { center: true })
}

if (document.readyState === 'complete') {
    init()
} else {
    document.addEventListener('DOMContentLoaded', () => init())
}

const parser = new DOMParser()
swup?.on('contentReplaced', () => {
    const { originalContent } = swup?.cache.getCurrentPage()
    const page = parser.parseFromString(originalContent, 'text/html')

    const header = page.documentElement.querySelector('[data-page-header]'),
        footer = page.documentElement.querySelector('body > footer'),
        lang = page.documentElement.getAttribute('lang');

    document.querySelector('[data-page-header]').innerHTML = header.innerHTML
    document.querySelector('body > footer').innerHTML = footer.innerHTML
    document.documentElement.setAttribute('lang', lang);
    init()
})

swup?.on('pageView', () => {
    document.body.classList.remove('u-hiddenOverflow')
    navOpener.closest('[data-page-header]')?.classList.remove('is-opened')
})